



// function SavePlayerStats(player) {

// }

// function GetGames() {

// }

// function CreateGame(game){

// }

// function AddPlayerToGame(player, game){

// }

export async function GetPlayers(game){
    
    return fetch(process.env.REACT_APP_APIHOST + '/api/LaxStatTracker')
    .then(data =>
      {
        if(data.status === 200) {          
          return data.json()
          .then(jsonData => {
            let returnData = jsonData;
            if(game.isNew) {
                returnData = initStats(jsonData);
            }
            return returnData;
          });
        }
        else {
            if(process.env.NODE_ENV === "development"){
                return initStats(mockPlayers);
            }
            else return [];
        }
      }      
    )
    .catch((e) => {
            if(process.env.NODE_ENV === "development"){
                return initStats(mockPlayers);
            }
            else return [];
        }
    );
}





/*
* Initial player stats for a game
*/
function initStats(players) {
    
    players.forEach(player => {
        player.groundballs = 0
    });
    return players;
}

const mockPlayers = [
    {
        firstName: "(Mock) Brennan",
        lastName: "Summers",
        number: 21,
        position: "Midfield"
    },
    {
        firstName: "(Mock) Kieran",
        lastName: "Summers",
        number: 8,
        position: "Midfield"
    },
    {
        firstName: "(Mock) Paul",
        lastName: "Summers",
        number: 33,
        position: "Goalie"
    }
  ]