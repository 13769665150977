import React, {useState} from 'react';




function StatCaptureList(props)  {

    const [playerStats, setPlayerStats] = useState(props.players);

    const updatePlayer = (thePlayer) => {
                
        let pstats = playerStats.map((player, index) => {
            let p = player;
            if(player.number === thePlayer.number) {
                p = thePlayer;
            }            
            return p;
        });
        setPlayerStats(pstats);
    }
    return playerStats.map((pyr, i) => {
        return <StatCaptureItem key={pyr.number} firstName={pyr.firstName} 
                    lastName={pyr.lastName} position={pyr.position} 
                    number={pyr.number} groundballs={pyr.groundballs} onClick={updatePlayer} />
    })
    
}

function StatCaptureItem(props) {

    const [player, setPlayer] = useState(props);

    const addGroundBall = () => {
        setPlayer(
            {
                firstName: player.firstName,
                lastName: player.lastName,
                position: player.position,
                number: player.number,
                groundballs: player.groundballs + 1
            }
        )
        props.onClick(player);
    }

    return (<div style={{marginTop: '10px'}}>
                <div style={{float:'left', width: '25px'}}>
                    {player.number}
                </div>
                <div style={{float:'left', width: '200px'}}>
                    {player.firstName} {player.lastName}
                </div>
                <div style={{float:'left', width: '75px'}}>
                    {player.position}
                </div>
                <div style={{float:'none'}}>
                    <button onClick={addGroundBall} style={{width: '50px', height: '50px'}}>{player.groundballs}</button>
                </div>
            </div>)
}

export default StatCaptureList;