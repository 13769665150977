import React, {useEffect, useState} from 'react';
import Header from './components/header.jsx';
import StatCaptureList from './components/stat-capture.jsx';
import {GetPlayers} from './services/StatService.js';


// const mockPlayers = [
//   {
//       firstName: "(Mock) Brennan",
//       lastName: "Summers",
//       number: 21,
//       position: "Midfield"
//   },
//   {
//       firstName: "(Mock) Kieran",
//       lastName: "Summers",
//       number: 8,
//       position: "Midfield"
//   },
//   {
//       firstName: "(Mock) Paul",
//       lastName: "Summers",
//       number: 33,
//       position: "Goalie"
//   }
// ]
// /*
// * Initial player stats for a game
// */
// function initStats(players) {
    
//   players.forEach(player => {
//       player.groundballs = 0
//   });
//   return players;
// }

function App() {
  const[players, setPlayers] = useState([]);
  
  useEffect(() => { 
    GetPlayers({isNew:true})
    .then(
      (playerData) => {
        console.log("Got the player data");
        setPlayers(playerData);
      }
    )
    // fetch(process.env.REACT_APP_APIHOST + '/api/LaxStatTracker')
    // .then(data =>
    //   {
    //     if(data.status === 200) {          
    //       data.json()
    //       .then(jsonData => setPlayers(initStats(jsonData)));
    //     }
    //     else {
    //       setPlayers(initStats(mockPlayers));
    //     }
    //   }      
    // ).catch((e) => {
    //   setPlayers(initStats(mockPlayers));
    // })       
    }, []);
  
  
  var renderEl = <div>No player data available</div>
  if(players && players.length > 0) { renderEl = <StatCaptureList players={players} /> }
  return (
    <div>
      <Header />
      {renderEl}
    </div>
  );
}

export default App;
